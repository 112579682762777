.p-heading {
  align-items: center;
  font-weight: bold;
  justify-content: space-around;
  font-size: 4rem;
  font-style: italic;
  padding: 0 13rem;
  color: #fff;
}
.p-cards {
  gap: 1rem;
  padding: 2rem;
}

.card {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  gap: 2rem;
  color: white;
  justify-content: space-between;
  transition: 0.6s ease;
}

.card > :nth-child(1) {
  width: 2rem;
  height: 2rem;
  fill: white;
}
.card > :nth-child(2) {
  font-weight: bold;
  font-size: 2rem;
}
.card > :nth-child(3) {
  font-size: 0.99rem;
  color: #fff;
  line-height: 25px;
}
.p-join {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.p-join img {
  width: 1rem;
}

.card:hover {
  background: var(--planCard);
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .programs {
    padding: 0 2rem;
  }
  .p-cards {
    padding: 0;
  }
  .p-heading {
    flex-direction: column;
    font-size: xx-large;
    flex-direction: column;
    /* font-size: large; */
    justify-content: center;
    align-items: flex-start;
    margin-top: 2rem;
    padding: 0 0.5rem;
  }
  .p-cards {
    flex-direction: column;
  }
  .card {
    padding: 0.5rem;
    gap: 0.5rem;
  }
  .card > :nth-child(2) {
    font-weight: bold;
    font-size: large;
  }
  .card > :nth-child(3) {
    font-size: small;
  }
  .p-join {
    padding: 0rem !important;
    font-size: small !important;
    gap: 0.5rem !important;
  }
}
@media screen and (max-width: 1080px) and (min-width: 768px) {
  .p-heading {
    padding: 0 5rem;
    font-size: 2.5rem;
  }
  .card > :nth-child(2) {
    font-weight: bold;
    font-size: 1rem;
  }
  .card > :nth-child(3) {
    font-size: 0.75rem;
  }
}

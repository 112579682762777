.reasons {
  display: flex;
  gap: 2rem;
  padding: 0 2rem;
}

.r-left {
  flex: 1 1;
}

.r-left > img {
  object-fit: cover;
}

.r-right > img {
  object-fit: cover;
  width: 100%;
}
.img-wraper {
  gap: 1rem;
}
.img-left {
  flex: 1;
  height: 40rem;
  overflow: hidden;
}
.img-right {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.top {
  height: 19rem;
  overflow: hidden;
}
.button {
  display: flex;
  gap: 1rem;
  height: 19rem;
}
.b-left {
  flex: 1;
}
.b-right {
  flex: 1;
}
.h-100 {
  height: 100%;
}
.r-right {
  flex: 1 1;
}
.r-right > span {
  color: var(--orange);
  font-weight: bold;
  font-size: 2rem;
}
.r-right > div > span {
  font-size: 3rem;
  font-weight: bold;
}
.text {
  color: white;
}

.r-details {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin-top: 2rem;
}
.r-details > div {
  display: flex;
  gap: 1rem;
  align-items: center;
  color: white;
  font-size: 1rem;
}
.r-details > div > img {
  width: 2rem;
  height: 2rem;
}

.w-100 {
  width: 100%;
}
.partner-text {
  font-weight: normal !important;
  color: var(--gray) !important;
  display: block;
  margin-top: 3rem;
}
.partners {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}
.partners > img {
  width: 2.5rem;
}
@media screen and (max-width: 768px) {
  .reasons {
    flex-direction: column;
  }
  .top {
    height: auto;
  }
  .h-100 {
    height: auto;
  }
  .img-left {
    height: auto;
  }
  .button {
    height: auto;
  }
}
@media screen and (max-width: 1080px) and (min-width: 768px) {
  .top,
  .button {
    height: auto;
  }
}

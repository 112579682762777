.header {
  justify-content: space-between;
}

.nav-menu {
  display: flex;
  list-style: none;
  gap: 2rem;
  color: #fff;
}

.logo {
  width: 10rem;
  height: 3rem;
}
.nav-menu > li {
  background: linear-gradient(90deg, transparent, var(--orange), transparent)
    bottom / var(--d, 0) 3px no-repeat;
  transition: 0.5s;
}

.nav-menu > li:hover {
  cursor: pointer;
  color: var(--orange);
  --d: 100%;
}

.bars {
  display: none;
}
@media only screen and (max-width: 767px) {
  .d-none {
    display: none;
  }
  .bars {
    display: block;
    position: absolute;
    display: block;
    position: absolute;
    right: 5rem;
    background: black;
    padding: 1rem;
    width: 1rem;
    top: 2rem;
    z-index: 99;
  }

  .nav-menu {
    flex-direction: column;
    background: black;
    position: absolute;
    padding: 2rem;
    right: 5rem;
    top: 2rem;
  }
}
